/*----------------------------------------*/
/*  02. Header
/*----------------------------------------*/
/* ---Header Top--- */
.dropdown{
    &-wrap{
        display: flex;
        align-items: center;
        li{
            .ht-btn{
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 0;
                padding-right: 0;
                &:after{
                    font-family: FontAwesome;
                    border: 0;
                    content: "\f078";
                    font-size: 10px;
                    border-right: 1px solid $border-color;
                    padding-right: 15px;
                    margin-right: 15px;
                }
            }
        }
    }
    &-menu{
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        border: 0;
        li{
            a{
                font-size: 14px;
            }
        }
    }
}
.header{
    &-top{
        &-right{
            display: flex;
            justify-content: flex-end;
            .shipping{
                letter-spacing: 0.8px;
                font-size: 14px;
                span{
                    color: $primary;
                }
            }
        }
    }
}

/* ---Header Middle--- */
.header{
    &-middle{
        &-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &-search-area{
        width: 730px;
        @include media-breakpoint-down(xl){
            width: 520px;
        }
    }
    &-searchbox{
        display: flex;
        width: 100%;
        .select-search-category{
            border-radius: 5px 0 0 5px;
            margin-right: 5px;
            max-width: 200px;
            height: 50px;
            line-height: 50px;
            color: #555252;
            &:focus{
                border-color: #e8e8e8;
            }
            .list{
                width: 200px;
            }
        }
        .input-field{
            border: 1px solid #e8e8e8;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 5px;
            width: 100%;
        }
        .btn{
            &-outline-whisper{
                color: initial;
                font-size: 23px;
                i{
                    vertical-align: middle;
                }
            }
        }
    }
}

/* ---Main Header--- */
.main{
    &-nav{
        > ul{
            display: flex;
            justify-content: center;
            > li{
                display: inline-block;
                &:not(:last-child){
                    padding-right: 30px;
                }
                > a{
                    font-weight: 500;
                    color: $white;
                    display: flex;
                    align-items: center;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-size: 15px;
                    i{
                        margin-left: 5px;
                        font-size: 10px;
                    }
                }
                &:hover{
                    > .drop-menu{
                        top: 100%;
                        opacity: 1;
                        visibility: visible;
                        > li{
                            &:hover{
                                > .drop-menu{
                                    top: 0;
                                    opacity: 1;
                                    visibility: visible;
                                    > li{
                                        &:hover{
                                            > .drop-menu{
                                                top: 100%;
                                                opacity: 1;
                                                visibility: visible;
                                                @include media-breakpoint-up(xxl){
                                                    top: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Drop Menu--- */
.drop{
    &-holder{
        position: relative;
    }
    &-menu{
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        background-color: $white;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        top: calc(100% + 30px);
        left: 0;
        transition: $baseTransition;
        width: 250px;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: left;
        z-index: 2;
        @include media-breakpoint-down(xl){
            left: auto;
            right: 0;
        }
        > li{
            &:not(:last-child){
                padding-bottom: 10px;
            }
            &:hover{
                > a{
                    color: $primary;
                }
            }
            > .drop{
                &-menu{
                    left: 100%;
                    > li{
                        > .drop{
                            &-menu{
                                left: auto;
                                right: calc(100% + 30px);
                                @include media-breakpoint-up(xxl){
                                    left: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        a{
            color: #000000;
            font-size: 14px;
            
        }
    }
}

/* ---Sub Dropdown--- */
.sub-dropdown{
    &.drop-menu{
        top: 30px;
        left: auto;
        right: 100%;
    }
    &-holder{
        &:hover{
            .sub-dropdown{
                &.drop-menu{
                    top: 0;
                    opacity: 1;
                    visibility: visible;
                }
            }
            > a{
                color: $primary;
            }
        }
    }
}

/* ---Megamenu--- */
.megamenu{
    display: flex;
    width: 100%;
    padding: 0;
    .title{
        padding-top: 35px;
        padding-left: 35px;
        padding-right: 35px;
        margin-bottom: 15px;
        @include media-breakpoint-only(lg){
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    &.drop-menu{
        > li{
            padding-bottom: 0;
        }
    }
    > li{
        width: 25%;
        &:nth-child(-n+2){
            border-right: 1px solid rgba(173, 181, 189, 0.15);
        }
        &:first-child{
            margin-right: 0;
        }
        &:nth-child(3){
            padding-right: 0;
        }
        > ul{
            li{
                border-bottom: 1px solid rgba(173, 181, 189, 0.15);
                &:first-child{
                    border-top: 1px solid rgba(173, 181, 189, 0.15);
                }
                a{
                    color: #000000;
                    display: block;
                    padding: 15px 35px;
                    transition: $baseTransition;
                    @include media-breakpoint-only(lg){
                        padding: 15px 25px;
                    }
                    &:hover{
                        background-color: #f8f9fa;
                        color: #000000;
                    }
                }
            }
        }
        .menu-slider{
            height: 100%;
            .single-item{
                display: block;
                height: 100%;
                img{
                    height: 100%;
                }
            }
        }
    }
    &-holder{
        position: static;
        .title{
            color: $secondary;
            display: block;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

/* ---Header Right--- */
.header-right{
    > ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        > li{
            position: relative;
            cursor: pointer;
            &:not(:last-child){
                padding-right: 15px;
            }
            > a{
                i{
                    font-size: 23px;
                    vertical-align: middle;
                }
            }
            .minicart{
                &-btn{
                    .quantity{
                        background-color: $primary;
                        border-radius: 100%;
                        text-align: center;
                        font-size: 14px;
                        color: $white;
                        display: block;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        position: absolute;
                        top: -5px;
                        right: 0;
                    }
                }
            }
        }
        .btn{
            display: flex;
            i{
                color: $dark;
                font-size: 24px;
            }
            &:after{
                border: 0;
            }
        }
    }
}

/* ---Header Logo--- */
.header-logo{
    display: flex;
    align-items: center;
}

/* ---Header Sticky--- */
.sticky {
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
    background-color: $white;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
    display: block;
    .header-logo-wrap{
        top: 0;
        height: 100%;
    }
}